@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.live-header-container {
  height: 90px;
}

.live-header {
  position: relative;
  z-index: 1;
  border-top: 2px solid #4e4e4e;
  height: 90px;
  background-color: #f0f0f0;
  transition: height ease-out .4s;
  &.affix {
    position: fixed;
    height: 45px;
    width: 100%;
    .live-container {
      height: 45px;
    }
    .live-search-form {
      //display: none;
      opacity: 0;
    }
    .live-logo {
      img {
        width: 45px;
        height: 45px;
      }
    }
  }
  &.affix-top {
    .live-search-form {
      transition-property: opacity, left;
      transition-duration: .1s, .4s;
      transition-delay: .4s, 0s;
      transition-timing-function: ease-out, ease-out;
    }
  }
  .live-search-form {
    transition-property: opacity, left;
    transition-duration: .1s, .4s;
    transition-delay: 0s, 0s;
    transition-timing-function: ease-out, ease-out;
    opacity: 1;
  }
  .live-container {
    position: relative;
    height: 90px;
    transition: height ease-out .4s;
  }
  .live-icons {
    position: relative;
    z-index: 1;
    .text-label {
      display: none;
    }
    a {
      color: #535353;
      float: left;
      position: relative;
      text-decoration: none;
      line-height: 40px;
      margin-left: 5px;
      padding-top: 8px;
      height: 40px;
      width: 40px;
      text-align: center;
      vertical-align: middle;
      &:hover {
        color: #535353;
        text-decoration: none;
      }
      .fa {
        position: relative;
        font-size: 25px;
        line-height: 1;
        vertical-align: middle;
      }
      .cart-amount {
        font-family: 'Open Sans', sans-serif;
        color: #ff0000;
        font-size: 9px;
        position: absolute;
        top: -6px;
        right: -8px;
      }
    }
    .live-products-trigger {
      .fa {
        width: 18px;
      }
      &.open {
        .fa.fa-times {
          display: inline-block;
        }
        .fa.fa-bars {
          display: none;
        }
      }
      .fa.fa-times {
        display: none;
      }
    }
  }
}

.live-logo {
  position: absolute;
  bottom: 0;
  left: 15px;
  img {
    display: block;
    height: 90px;
    width: 90px;
    transition: all ease-out .4s;
  }
}

.live-header-bar {
  height: 30px;
  border-bottom: 4px solid #f0f0f0;
  background-color: #525252;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

  .live-header-container {
    height: 45px;
  }

  .live-header {
    height: 45px;
    &.affix {
      .live-search-form {
        left: 70px;
        opacity: 1;
      }
      .live-logo {
        bottom: 0;
        left: 15px;
        img {
          width: 45px;
          height: 45px;
        }
      }
    }
    .live-container {
      height: 43px;
    }
    .live-icons {
      .text-label {
        display: inline-block;
        margin-right: 2px;
      }
      a {
        &:first-child {
          margin-left: 0;
        }
        margin-left: 20px;
        width: auto;
        padding-top: 0;
        .fa {
          font-size: 20px;
        }
      }
    }
    .live-search-form {
      left: 15px;
      top: 5px;
      .live-control-container {
        left: 0;
        right: 450px;
      }
      button[type="submit"] {
        right: 420px;
        font-size: 16px;
      }
    }
  }

  .live-logo {
    left: 45px;
    bottom: -75px;
    transition: all ease-out .4s;
    img {
      width: 80px;
      height: 80px;
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

  .live-header-container {
    height: 38px;
  }

  .live-header {
    height: 38px;
    &.affix {
      position: relative;
      height: 38px;
      .live-search-form {
        left: 15px;
      }
    }
    .live-icons {
      a {
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        .fa {
          font-size: 18px;
        }
      }
    }
    .live-logo-bar-container {
      height: 0;
    }
    .live-search-form {
      .live-control-container {
        left: 50%;
        right: auto;
        width: 290px;
        margin-left: -155px;
        .form-control.live-form-control {
          height: 26px;
          font-size: 12px;
        }
      }
      button[type="submit"] {
        right: 317px;
        font-size: 16px;
        height: 26px;
      }
    }
  }
  .live-logo {
    left: -167px;
    bottom: -55px;
    img {
      width: 115px;
      height: 115px;
    }
  }
}