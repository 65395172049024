.live-product-search {
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 9px;
  padding: 6px 10px;
  .form-group {
    label {
      font-weight: normal;
    }
    .form-control.live-form-control {
      background-color: #f1f1f1;
    }
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.live-search-list {
  list-style: none;
  padding: 0;
  li {
    background-color: #f1f1f1;
    margin-bottom: 10px;
    padding: 10px;
    .image-container {
      display: block;
      text-align: center;
      margin-bottom: 10px;
      img {
        max-height: 95px;
        max-width: 150px;
      }
    }
    .item-text {
      h4 {
        font-size: 18px;
        margin-top: 0;
        a {
          color: #666666;
          text-decoration: none;
          &:hover {
            color: #999999;
          }
          .item-price {
            background-color:#FF0000;
            color:#FFFFFF;
            font-weight:600;
            font-size:14px;
          }
        }
      }
      p {
        font-size: 12px;
        &.price {
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: 768px) {

  .live-product-search {
    form {
      padding-left: 30px;
    }
    .form-group {
      margin-bottom: 0;
      &.result-group {
        padding-left: 10px;
      }
    }
  }

  .live-search-list {
    li {
      position: relative;
      .align-helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
      .image-container {
        position: absolute;
        left: 10px;
        top: 10px;
        bottom: 10px;
        margin-bottom: 0;
        width: 160px;
        &:hover {
          text-decoration: none;
        }
      }
      .item-text {
        padding-left: 160px;
      }
    }
  }
}
@media (min-width: 992px) {
  .live-product-search {
    height: 46px;
  }
}
