html, body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  background-color: #ffffff;
}

@import "header";
@import "product-list";
@import "cart";

.live-landing-image {
  width: 100%;
}

.live-sidebar-menu {
  list-style: none;
  padding: 0;
  .header {
    padding-left: 0;
    a img {
      width: 125px;
    }
  }
  li {
    line-height: 1.3;
    padding-left: 25px;
    &.active {
      .live-subcats {
        display: block;
      }
    }
    a {
      color: #666666;
      display: block;
      padding: 2px 0;
      font-size: 12px;
      text-decoration: none;
      &:hover {
        color: #000000;
        text-decoration: none;
      }
    }
    .live-subcats {
      display: none;
      padding-left: 22px;
      margin-top: 5px;
      margin-bottom: 5px;
      a {
        display: block;
        padding-top: 2px;
        height: auto;
        &.active {
          color: #5db95f;
        }
      }
    }
  }
}

.live-cat-header {
  color: #61bb46;
  font-size: 12px;
  font-weight: 600;
}

.live-categories {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    &:nth-child(2n+1) {
      a {
        background-color: #f0f0f0;
      }
    }
    &.active {
      .live-subcats {
        display: block;
      }
      .arrow {
        transform: rotate(90deg);
      }
      .text-container {
        font-weight: bold;
      }
    }
    a {
      position: relative;
      display: block;
      min-height: 60px;
      padding-left: 4px;
      color: #666666;
      &:hover, &:focus {
        text-decoration: none;
        color: #666666;
      }
    }
    .img-container {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 50%;
      margin-top: -30px;
      img {
        display: block;
        max-height: 60px;
        max-width: 60px;
      }
    }
    .text-container {
      display: block;
      font-size: 14px;
      padding: 21px 40px 21px 90px;
    }
    .arrow {
      position: absolute;
      font-size: 14px;
      right: 25px;
      top: 50%;
      margin-top: -10px;
    }
    .live-subcats {
      display: none;
      a {
        padding-left: 110px;
        padding-top: 2px;
        min-height: 22px;
        height: auto;
        &.active {
          color: #61bb46;
        }
      }
    }
  }
}

.live-footer-menu {
  min-height: 40px;
  background-color: #f0f0f0;
  text-align: center;
  font-size: 11px;
  padding-top: 14px;
  a {
    color: #373737;
    text-decoration: none;
    &:hover, &:active {
      color: #373737;
      text-decoration: none;
    }
  }
}

.live-copyright {
  min-height: 40px;
  text-align: center;
  font-size: 10px;
  padding-top: 15px;
}

.live-footer-links {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 30px;
  .promoters {
    margin-top: 10px;
    a {
      img {
        height: 30px;
      }
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    img {
      display: inline-block;
      height: 25px;
    }
  }
  .row {
    & > div {
      margin-bottom: 10px;
    }
  }
}

.live-product-title {
  color: #5e6562;
  background-color: #ffffff;
  min-height: 60px;
  padding: 18px 0 6px 0;
  position: relative;
  h5 {
    font-weight: 600;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1.3;
  }
}

.live-breadcrumb {
  font-family: Arial, sans-serif;
  font-style: italic;
  font-size: 13px;
  a {
    color: #999999;
    text-decoration: none;
    font-weight: 600;
    &:hover, &:focus {
      color: #5e6562;
      text-decoration: none;
    }
    &:last-child {
      color: #5db95f;
    }
  }
}

.live-product-image {
  text-align: center;
  img {
    margin: 0 auto;
    max-height: 425px;
  }
}

.live-products-list {
  &.live-recommendations {
    margin-bottom: 20px;
    .products-list {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      background-color: #ffffff;
    }
  }
  p {
    font-size: 10px;
  }

  .products-list-title {
    strong {
      display: block;
    }
  }

  .products-list {
    background-color: #ffffff;
    a {
      img {
        display: block;
        margin: 0 auto 2px auto;
        width: 48px;
      }
      span {
        display: block;
      }
      strong {
        display: block;
      }
      color: #473F4B;
      text-decoration: none;
      float: left;
      width: 16.66%;
      font-size: 9px;
      text-align: center;
      padding: 4px 0;
      &:hover, &:active {
        color: #473F4B;
        text-decoration: none;
      }
    }
  }
}

.live-price-section {
  padding: 20px 0;
  .live-price-button {
    padding-top: 7px;
    text-align: center;
    margin-bottom: 15px;
    .btn.live-btn {
      height: 40px;
      font-size: 16px;
      font-weight: bold;
      padding: 0 51px;
    }
  }
  .live-price-amount {
    font-size: 12px;
    text-align: center;
    .live-price-ron {
      display: inline-block;
      margin-bottom: 10px;
      color: #313131;
      font-size: 13px;
      span {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .live-price-euro {
      line-height: 1.1;
    }
  }
}

.live-product-description {
  color: #666666;
  font-size: 12px;
  margin-bottom: 12px;
  .read-more {
    font-family: Arial, sans-serif;
    font-weight: 600;
    font-style: italic;
    color: #61bb46;
    text-decoration: none;
    &.less {
      display: none;
    }
    &:hover, &:active {
      color: #61bb46;
      text-decoration: none;
    }
  }
  .read-less {
    font-family: Arial, sans-serif;
    font-weight: 600;
    font-style: italic;
    color: #61bb46;
    text-decoration: none;
    display: none;
    &:hover, &:active {
      color: #61bb46;
      text-decoration: none;
    }
  }
  .product-more-desc {
    display: none;
  }
}

.btn.live-btn {
  font-size: 14px;
  height: 30px;
  border-radius: 0;
  font-weight: bold;
  font-style: italic;
  padding: 0 43px;
}

.live-search-form {
  height: 30px;
  position: absolute;
  top: 45px;
  right: 15px;
  left: 115px;
  label {
    color: #535353;
    font-weight: normal;
    font-size: 13px;
  }
  .live-control-container {
    position: absolute;
    left: 0;
    right: 30px;
    //top: 2px;
  }
  .form-control.live-form-control {
    border: 0 none;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    display: block;
    width: 100%;
  }
  button[type="submit"] {
    color: #535353;
    border: 0 none;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #ffffff;
    font-size: 20px;
    height: 30px;
    width: 30px;
    position: absolute;
    right: 0;
    padding: 1px 5px 1px 0;
  }
}

.form-control.live-form-control {
  height: 30px;
  border-radius: 3px;
  box-shadow: none;
  border: 0 none;
  font-size: 14px;
  padding: 0 5px;
}

.live-sidebar {
  background-color: #f0f0f0;
  float: left;
  width: 180px;
  margin-top: 121px;
  margin-bottom: 10px;
  padding-top: 8px;
  position: relative;
  overflow: visible;
  .live-sidebar-overlay {
    position: absolute;
    top: 0;
    background-color: #f0f0f0;
    right: 0;
    bottom: 0;
    left: -1000px;
    z-index: 1;
  }
  .live-sidebar-menu {
    position: relative;
    z-index: 2;
  }
}

.live-product-description-big {
  margin-bottom: 10px;
  font-size: 10px;
  .description-title {
    float: left;
    width: 110px;
    p {
      padding-left: 0;
      strong {
        display: block;
      }
    }
  }
  .description-text {
    float: left;
    max-width: 665px;
  }
}

.live-categories-big {
  background-color: #f0f0f0;
  padding: 4px 0;
  text-align: center;
  a {
    color: #101010;
    display: inline-block;
    font-size: 11px;
    text-decoration: none;
    min-width: 60px;
    max-width: 79px;
    text-align: center;
    vertical-align: top;
    &:hover {
      color: #000000;
      text-decoration: none;
    }
    span {
      display: block;
      text-align: center;
      img {
        max-width: 60px;
      }
    }
  }
}

.container.live-container {
  width: 100%;
}

.live-menu-container {
  position: fixed;
  top: 45px;
  width: 100%;
  right: -100%;
  bottom: 0;
  z-index: 2;
  background-color: #ffffff;
  opacity: 0;
  overflow: auto;
  transition: all .4s ease-out;
  &.open {
    right: 0;
    opacity: 1;
  }
  .live-cat-header {
    padding-left: 10px;
  }
}

.live-page {
  width: 100%;
}

.live-page-content {
  width: 100%;
}

.live-link {
  &:hover {
    color: #5e6562;
    text-decoration: none;
  }
  color: #5db95f;
}

.live-bookmark-link {
  &:hover {
    color: #333333;
    text-decoration: none;
  }
  color: #333333;
}

.live-page.live-static-page {
  margin-top: 15px;
}

.live-cookies {
  display: none;
  padding: 10px;
  background-color: #e0e0e0;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  .btn.live-btn {
    margin-left: 12px;
  }
}

@media (max-width: 767px) {
  .live-page.live-static-page {
    text-align: center;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

  .live-price-section {
    .live-price-button {
      text-align: left;
      .btn.live-btn {
        height: 30px;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .live-price-amount {
      text-align: left;
      .live-price-euro {
        font-size: 11px;
        line-height: 12px;
      }
    }
  }

  .live-products-list {
    &.live-recommendations {
      margin-bottom: 20px;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      .products-list {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .live-product-title {
    padding-left: 156px;
    h5 {
      font-size: 24px;
      margin-bottom: 4px;
    }
  }

  .live-product-description {
    padding-left: 482px;
  }

  .live-product-image {
    float: left;
    padding-left: 20px;
    width: 482px;
  }

  .live-products-list {
    margin-bottom: 10px;
    &.live-recommendations {
      background-color: #ffffff;
      margin-bottom: 20px;
      padding-top: 10px;
      .products-list {
        margin-left: 130px;
        background-color: #ffffff;
        width: 384px;
      }
    }

    .products-list-title {
      float: left;
      width: 130px;
      p {
        padding-left: 0;
      }
    }

    .products-list {
      a {
        img {
          width: 48px;
          display: block;
          margin: 0 auto 2px auto;
        }
        color: #473F4B;
        padding: 0 3px;
        width: 64px;
      }
    }
  }

  .live-menu-container {
    width: 300px;
    right: -300px;
  }

  .live-page {
    width: 100%;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .container.live-container {
    width: 992px;
  }
  .live-page-content {
    float: left;
    //padding-top: 45px;
    padding-left: 7px;
    max-width: 782px;
  }
  .live-product-title {
    padding-left: 0;
    padding-top: 10px;
    height: 66px;
    h5 {
      font-weight: 500;
      margin-bottom: 4px;
    }
  }
  .live-breadcrumb {
    font-size: 11px;
    a {
      //font-weight: 500;
      &:last-child {
        //font-weight: 600;
      }
    }
  }
  .live-product-description {
    padding-top: 50px;
  }

  .live-footer-menu {
    background-color: transparent;
    float: right;
    padding-right: 15px;
  }
  .live-copyright {
    float: left;
  }
  .live-footer-links {
    margin-top: 10px;
    .promoters {
      margin-top: 0;
    }
  }

  .live-products-list {
    &.live-recommendations {
      background-color: transparent;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 20px;
      .products-list {
        margin-left: 110px;
        width: 384px;
      }
    }

    .products-list-title {
      float: left;
      width: 110px;
      p {
        padding-left: 0;
      }
    }

    .products-list {
      a {
        img {
          width: 48px;
          display: block;
          margin: 0 auto 2px auto;
        }
        color: #473F4B;
        padding: 0 3px;
        width: 64px;
      }
    }
  }

  .live-page {
    width: 782px;
  }

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}