.form-control.live-order-control {
  font-size: 13px;
  height: 20px;
  padding: 2px;
  border: 1px solid #666666;
  border-radius: 0;
  &.live-form-field {
    height: 24px;
    margin-top: 4px;
  }
}

textarea.form-control.live-order-control.live-form-field {
  height: auto;
}

.control-label {
  &.live-form-label {
    font-size: 12px;
    font-weight: normal;
  }
}

.live-cart-table {
  margin-bottom: 20px;

  thead {
    tr {
      background-color: #ffffff;
      th {
        text-align: center;
        font-weight: normal;
        border-bottom: 1px solid #dddddd;
        font-size: 12px;
      }
    }
  }

  tbody {
    tr {
      td {
        text-align: center;
      }
    }
  }

  .product-image {
    max-height: 50px;
    max-width: 74px;
  }

  .live-amount-btn {
    border: 0 none;
    margin: 3px 0 0 0;
    width: 20px;
    height: 20px;
    padding: 0;
    display: inline-block;
    &.live-delete {
      color: #FFFFFF;
      background: #999999;
    }
    &.live-subtract {
      color: #333333;
      background: #CCFF35;
    }
    &.live-add {
      color: #333333;
      background: #CCFF35;
    }
    &.live-refresh {
      color: #333333;
      background: #CCFF35;
    }
  }

  .live-vertical-cell {
    vertical-align: middle;
  }

  .form-control {
    display: inline-block;
    width: 40px;
  }

  .live-btn-container {
    margin-top: 5px;
    display: inline-block;
  }

  .live-amount-form {
    display: inline-block;
    margin-bottom: 0;
  }

  .live-link {
    color: #5e6562;
    &:hover {
      color: #5db95f;
    }
  }

  .live-total-row {
    td {
      padding-top: 10px;
    }

    .live-total-value {
      text-align: left;
    }

    .live-total-label {
      text-align: right;
    }

    .live-total-amount {
      color: #ffffff;
      background-color: #5db95f;
      padding: 0 5px;
    }

    .live-total-amount, .live-total-label {
      font-size: 16px;
    }
  }

  .live-name-cell {
    text-align: left;
  }
}

.live-page.live-cart-page {
  margin-top: 20px;
  h5 {
    font-weight: bold;
  }
}

.live-company-group {
  display: none;
}

.live-send-group {
  & > div {
    text-align: center;
  }
}

.live-popover {
  display: none;
  border: 3px solid #dddddd;
  width: 460px;
  background-color: rgba(255, 255, 255, .8);
  z-index: 1;
  max-height: 399px;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .live-cart-table {
    .live-btn-container {
      margin-top: 0;
    }
  }
}

@media (min-width: 992px) {

}